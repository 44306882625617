<template>
    <div class="look-like">
        <div class="ll-image-container">
            <img
                src="@/assets/images/look-like.png"
                alt="Your Home Is Your Fortress"
            />
        </div>
        <div class="ll-details">
            <h1>Your Home Is Your Fortress</h1>
            <p>
                Where you create happy memories and also soothe yourself during
                a difficult times. Designing your perfect space is not indulgent
                living, but a step towards mindful existence.
            </p>
            <div class="ll-button">
                <Button
                    v-if="isSQ == true && isPQ == true"
                    name="Explore Designs"
                    normal
                    :handleClick="openMDI"
                    class="project-button"
                />
                <Button
                    v-else
                    name="Start Your Project"
                    normal
                    :handleClick="startQuiz"
                    target="_self"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'

export default {
    name: 'LookLike',
    components: {
        Button,
    },
    props: {
        isSQ: Boolean,
        isPQ: Boolean,
    },
    data() {
        return {
            quizUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
            baseUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_BASE_URL_B2B
                    : process.env.VUE_APP_BASE_URL,
            showModal: false,
        }
    },
    methods: {
        startQuiz() {
            window.open(`${this.quizUrl}?startQuiz=true`, '_self')
        },
        openMDI() {
            window.open(`${this.baseUrl}mudi/designideas`, '_self')
        },
    },
}
</script>

<style lang="scss">
@import './LookLike.scss';
</style>
