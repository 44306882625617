<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="home-page">
            <IntroBanner
                :doPostContactData="doPostContactData"
                :isSQ="isSQ"
                :isPQ="isPQ"
            />

            <HowItWorks id="howitworks" />

            <div
                class="home-vt"
                style="width: 100%;height: 600px;display: flex;flex-direction: column;align-items: center;"
            >
                <iframe
                    style="width:80%; height: 100%;"
                    src="https://tours360.siloho.com/tours/cCg23G8NF1Z"
                    allowfullscreen="allowfullscreen"
                ></iframe>
            </div>

            <WeTakeCare
                id="wetakecare"
                :doPostContactData="doPostContactData"
            />

            <WhyUss id="whyus" />

            <LookLike id="LookLike" :isSQ="isSQ" :isPQ="isPQ" />

            <Deserve />

            <MeetDesigners id="meetdesigners" />

            <ExploreDesign
                :specialScenes="specialScenes"
                :inspirationalIdeas="inspirationalIdeas"
                :requiredDomain="requiredDomain"
            />

            <Vendors id="vendors" :vendors="vendorsList" />

            <TestimonailsCarousel id="successstories" :user="testimonialList" />

            <Footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import HomePageModule from 'store/modules/HomePage'
import ContactPageModule from 'store/modules/ContactPage'

import IntroBanner from 'componentsv2/IntroBanner'
import HowItWorks from 'componentsv2/HowItWorks'
import WeTakeCare from 'componentsv2/WeTakeCare'
import WhyUss from 'componentsv2/WhyUss'
import LookLike from 'componentsv2/LookLike'
import Deserve from 'componentsv2/Deserve'
import MeetDesigners from 'componentsv2/MeetDesigners'
import ExploreDesign from 'componentsv2/ExploreDesign'
import TestimonailsCarousel from 'componentsv2/TestimonailsCarousel'
import Vendors from 'componentsv2/Vendors'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

import Footer from 'componentsv2/Footer'

export default {
    name: 'HomePage',
    props: {
        isSQ: Boolean,
        isPQ: Boolean,
        requiredDomain: Boolean,
    },
    components: {
        IntroBanner,
        HowItWorks,
        WeTakeCare,
        WhyUss,
        LookLike,
        Deserve,
        MeetDesigners,
        ExploreDesign,
        TestimonailsCarousel,
        Vendors,
        Footer,
        Loader,
    },
    metaInfo() {
        return {
            title: 'SILOHO-Home',
        }
    },
    mixins: [RegisterStoreModule, loaderHandler],
    data() {
        return {
            url:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
        }
    },
    computed: {
        ...mapState({
            roomCategoriesList: state =>
                state.HomePage.roomCategoriesList.filter_data,
            testimonialList: state => state.HomePage.testimonialList,
            vendorsList: state => state.HomePage.vendorsList,
            specialScenes: state => state.HomePage.specialScenes,
            inspirationalIdeas: state => state.HomePage.inspirationalIdeas,
        }),
    },
    created() {
        this.registerStoreModule('HomePage', HomePageModule)
        this.fetchRoomCategoriesList()
        this.fetchTestimonialList()
        this.fetchVendorsList()
        this.fetchSpecialScenes()
        this.fetchInspirationalIdeas()
            .then(res => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
        this.registerStoreModule('ContactPage', ContactPageModule)
        //this.doPostContactData()
    },
    destroyed() {
        this.$store.unregisterModule('HomePage')
    },
    methods: {
        ...mapActions({
            fetchRoomCategoriesList: 'fetchRoomCategoriesList',
            fetchTestimonialList: 'fetchTestimonialList',
            fetchVendorsList: 'fetchVendorsList',
            fetchSpecialScenes: 'fetchSpecialScenes',
            fetchInspirationalIdeas: 'fetchInspirationalIdeas',
            doPostContactData: 'doPostContactData',
        }),

        startQuiz() {
            window.open(`${this.url}?startQuiz=true`)
        },
    },
    mounted() {},
}
</script>

<style lang="scss" scoped>
@import './HomePage.scss';
</style>
