<template>
    <div class="everyone-deserve">
        <h1 class="everyone-head">You Deserve A Beautifully Designed Home</h1>
        <p class="everyone-body">
            Because It's an extension of you. The 3 Pillars of Good Design
        </p>
        <div class="ed-design-cards">
            <div class="flip-card">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <img src="@/assets/images/deserve-1.jpg" alt="It Is Personal"/>
                        <div class="img-t">
                            It Is Personal
                        </div>
                    </div>
                    <div class="flip-card-back">
                        <p class="head">It Is Personal</p>
                        <p class="sub">
                            A well designed home tells the world who you are,
                            wordlessly. It's a sacred space ( or atleast, should
                            be).
                        </p>
                    </div>
                </div>
            </div>
            <div class="flip-card">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <img src="@/assets/images/deserve-2.jpg"  alt="Definitely Ergonomic"/>
                        <div class="img-t">Definitely Ergonomic</div>
                    </div>
                    <div class="flip-card-back">
                        <p class="head">Definitely Ergonomic</p>
                        <p class="sub">
                            A ergonomic home is comfortable, safe and designed
                            to support you physically in every way.
                        </p>
                    </div>
                </div>
            </div>
            <div class="flip-card">
                <div class="flip-card-inner">
                    <div class="flip-card-front" id="center-crop">
                        <img src="@/assets/images/bedroom.jpg" alt="Infused With Meaning"/>
                        <div class="img-t">Infused With Meaning</div>
                    </div>
                    <div class="flip-card-back">
                        <p class="head">Infused With Meaning</p>
                        <p class="sub">
                            Lastly, every home is filled with meaning and
                            purpose. Does your space reflect your ideals and
                            vision?
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Deserve',
    data() {
        return {}
    },
}
</script>

<style lang="scss">
@import './Deserve.scss';
</style>
