<template>
    <div class="how-it-works">
        <div class="hwt-header">
            <p class="hwt-heading">How it Works</p>
            <h1 class="hwt-subheading">
                We Will Help You Furnish Your Home, Just The Way You Want It.
            </h1>
        </div>
        <div class="hwt-content">
            <div class="image-container">
                <img src="@/assets/images/howitworks.png"  alt="Interiors"/>
            </div>
            <div class="hwt-steps">
                <div class="steps-container">
                    <div class="steps">
                        <div class="icon-number">1</div>
                        <div class="step-details">
                            <h1>Unsure About What You Want?</h1>
                            <p>
                                Just take our style quiz! Its quick, fun and
                                will reveal your design personality.
                            </p>
                        </div>
                    </div>
                    <div class="steps">
                        <div class="icon-number">2</div>
                        <div class="step-details">
                            <h1>Choose A Design</h1>
                            <p>
                                Something caught your eye? Just click on what
                                you like.
                            </p>
                        </div>
                    </div>
                    <div class="steps">
                        <div class="icon-number">3</div>
                        <div class="step-details">
                            <h1>Now, Personalise</h1>
                            <p>
                                Add your personal touch and select from a
                                stunning selection of colours, woodworks and
                                products.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks',
    components: {},
}
</script>

<style lang="scss">
@import './HowItWorks.scss';
</style>
