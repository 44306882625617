<template>
    <div class="meet-designers">
        <div class="text-col">
            <h1 class="heading">Get To Know Your Designer</h1>
            <p class="subheading">
                So, what do you desire? A grown-up contemporary home or a
                minimalist one that reflects your spiritual nature? Or perhaps a
                bohemian dwelling which remind you of your childhood home? No
                matter what you seek, our designers are oozing talent,
                creativity and are experts in creating solutions.
            </p>
            <a href="/designers">
                <Button name="Our Fabulous Designers " primary />
            </a>
        </div>
        <div class="img-col">
            <img src="@/assets/images/designers-img.png" alt="Designers"/>
            <!-- <div class="designers-card-overlay">
                <div class="explore-designer-card card-1">
                    <img src="@/assets/images/ed-3.png" />
                    <h1>Jayashree Jain</h1>
                    <p>Projects : <span>12</span></p>
                    <div class="location">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            fill="none"
                            viewBox="0 0 14 20"
                        >
                            <path
                                fill="#000"
                                d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"
                            />
                        </svg>
                        Goa, India
                    </div>
                </div>
                <div class="explore-designer-card card-2">
                    <img src="@/assets/images/ed-3.png" />
                    <h1>Jayashree Jain</h1>
                    <p>Projects : <span>12</span></p>
                    <div class="location">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            fill="none"
                            viewBox="0 0 14 20"
                        >
                            <path
                                fill="#000"
                                d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"
                            />
                        </svg>
                        Goa, India
                    </div>
                </div>
                <div class="explore-designer-card card-3">
                    <img src="@/assets/images/ed-3.png" />
                    <h1>Jayashree Jain</h1>
                    <p>Projects : <span>12</span></p>
                    <div class="location">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            fill="none"
                            viewBox="0 0 14 20"
                        >
                            <path
                                fill="#000"
                                d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"
                            />
                        </svg>
                        Goa, India
                    </div>
                </div>
                <div class="explore-designer-card card-4">
                    <img src="@/assets/images/ed-3.png" />
                    <h1>Jayashree Jain</h1>
                    <p>Projects : <span>12</span></p>
                    <div class="location">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            fill="none"
                            viewBox="0 0 14 20"
                        >
                            <path
                                fill="#000"
                                d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"
                            />
                        </svg>
                        Goa, India
                    </div>
                </div>
                <div class="explore-designer-card card-5">
                    <img src="@/assets/images/ed-3.png" />
                    <h1>Jayashree Jain</h1>
                    <p>Projects : <span>12</span></p>
                    <div class="location">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="20"
                            fill="none"
                            viewBox="0 0 14 20"
                        >
                            <path
                                fill="#000"
                                d="M12.796 3.426c-.582-1.021-1.42-1.873-2.43-2.473-1.01-.6-2.159-.928-3.334-.951h-.308c-1.175.022-2.325.35-3.336.95-1.01.6-1.849 1.452-2.431 2.473-.61 1.04-.939 2.222-.955 3.427-.016 1.206.282 2.396.864 3.452l4.959 9.077.007.012c.105.184.258.337.441.443.184.107.392.163.604.163.213 0 .421-.056.605-.163.183-.106.335-.259.441-.443l.007-.012 4.957-9.079c.582-1.056.88-2.246.864-3.452-.016-1.205-.345-2.387-.955-3.427v.003zM6.875 9.063c-.556 0-1.1-.165-1.562-.474-.463-.309-.823-.748-1.036-1.262-.213-.514-.268-1.08-.16-1.625.109-.545.376-1.046.77-1.44.393-.393.894-.66 1.44-.769.545-.109 1.11-.053 1.624.16s.953.573 1.262 1.036c.31.462.474 1.006.474 1.562 0 .745-.297 1.46-.825 1.987-.527.528-1.241.824-1.987.825z"
                            />
                        </svg>
                        Goa, India
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'
export default {
    name: 'MeetDesigners',
    components: {
        Button,
    },
}
</script>

<style lang="scss">
@import './MeetDesigners.scss';
</style>
