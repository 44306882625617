<template>
    <div class="vendors-slider">
        <template v-if="vendors.payloads != null">
            <h1 class="header">Our Vendors</h1>
            <Carousel :settings="settings">
                <div
                    class="slider"
                    v-for="vendor in vendors.payloads"
                    :key="vendor.id"
                >
                    <router-link
                        :to="{
                            name: 'ProductListPage',
                            query: { brand_id: vendor.id },
                        }"
                    >
                        <div class="vendor-content">
                            <div class="vendor-image">
                                <img
                                    :src="
                                        vendor.image == ''
                                            ? require('assets/images/default-vendor.png')
                                            : vendor.image
                                    "
                                />
                            </div>
                            <!-- <div class="vendor-details">
                                
                            {{ vendor.name}}                                   
                            
                        </div> -->
                        </div>
                    </router-link>
                </div>
            </Carousel>
        </template>
    </div>
</template>

<script>
import Carousel from 'componentsv2/Carousel'
export default {
    components: {
        Carousel,
    },
    props: ['vendors'],
    data() {
        return {
            settings: {
                arrows: true,
                prevArrow: false,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 5,
                slidesToScroll: 1,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            infinite: true,
                        },
                    },
                ],
            },
        }
    },
}
</script>

<style lang="scss">
@import './Vendors.scss';
</style>
