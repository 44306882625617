<template>
    <div class="explore-design">
        <div class="heading">
            <h1>Customise Till Your Heart Sings</h1>
            <p>
                No, really we mean it. There is no such thing as too many
                choices and we want you explore, have fun and be picky. So go
                ahead and create your perfect space.
            </p>
        </div>
        <div
            class="suggested-design-card"
            v-if="Object.keys(specialScenes).length > 0"
        >
            <div class="image-wrapper">
                <img
                    :src="
                        specialScenes.scene_detail.scene_image_data_list[0]
                            .scene_image_url.w_image
                    "
                    :alt="specialScenes.scene_detail.room_type"
                />
            </div>
            <div class="design-details">
                <h1 class="design-name">
                    {{ specialScenes.scene_detail.room_type }} Design
                </h1>
                <p class="design-description">
                    {{ specialScenes.scene_detail.description }}
                </p>
                <div class="details">
                    <h1>Design</h1>
                    <p>{{ specialScenes.theme_data.name }}</p>
                </div>
                <div class="details">
                    <h1>Budget</h1>
                    <p :class="requiredDomain == false ? 'blur' : ''">
                        ₹
                        {{ convertToIndianNumberSystem(specialScenes.budget) }}
                    </p>
                </div>
                <div class="details">
                    <h1>Designed By</h1>
                    <p>{{ specialScenes.scene_detail.designer_name }}</p>
                </div>
                <router-link
                    :to="{
                        name: 'SceneDetailsPage',
                        params: { id: specialScenes.scene_detail.id },
                    }"
                >
                    <Button name="Explore the Design" primary />
                </router-link>
            </div>
        </div>

        <div class="look-more-design">
            <div class="header">
                <h1>Get Design Inspired</h1>
                <a href="/designideas" class="view-all">View All</a>
            </div>
            <p>We know you love home decor designs. So here you go.</p>
            <div
                class="design-list"
                v-if="Object.keys(inspirationalIdeas).length > 0"
            >
                <router-link
                    :to="{
                        name: 'RoomDesignIdeasPage',
                        params: { id: inspirationalIdeas.spaces[0].id },
                    }"
                >
                    <Cards
                        class="card"
                        :cardname="inspirationalIdeas.spaces[0].room"
                        :img="inspirationalIdeas.spaces[0].image.p_image"
                    />
                </router-link>
                <router-link
                    :to="{
                        name: 'RoomDesignIdeasPage',
                        params: { id: inspirationalIdeas.spaces[1].id },
                    }"
                >
                    <Cards
                        class="card"
                        :cardname="inspirationalIdeas.spaces[1].room"
                        :img="inspirationalIdeas.spaces[1].image.p_image"
                    />
                </router-link>
                <router-link
                    :to="{
                        name: 'RoomDesignIdeasPage',
                        params: { id: inspirationalIdeas.spaces[2].id },
                    }"
                >
                    <Cards
                        class="card"
                        :cardname="inspirationalIdeas.spaces[2].room"
                        :img="inspirationalIdeas.spaces[2].image.p_image"
                    />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'
import Cards from 'componentsv2/Cards'
import ConvertToPrice from 'mixins/ConvertToPrice'

// import { CURRENCY } from 'utils/constants'

export default {
    name: 'ExploreDesign',
    mixins: [ConvertToPrice],
    components: {
        Button,
        Cards,
    },
    props: {
        specialScenes: Object,
        inspirationalIdeas: Object,
        requiredDomain: Boolean
    },
    // mixins: [
    //     CURRENCY
    // ],
    data() {
        return {}
    },
}
</script>

<style lang="scss">
@import './ExploreDesign.scss';
</style>
