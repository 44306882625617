var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"explore-design"},[_vm._m(0),(Object.keys(_vm.specialScenes).length > 0)?_c('div',{staticClass:"suggested-design-card"},[_c('div',{staticClass:"image-wrapper"},[_c('img',{attrs:{"src":_vm.specialScenes.scene_detail.scene_image_data_list[0]
                        .scene_image_url.w_image,"alt":_vm.specialScenes.scene_detail.room_type}})]),_c('div',{staticClass:"design-details"},[_c('h1',{staticClass:"design-name"},[_vm._v(" "+_vm._s(_vm.specialScenes.scene_detail.room_type)+" Design ")]),_c('p',{staticClass:"design-description"},[_vm._v(" "+_vm._s(_vm.specialScenes.scene_detail.description)+" ")]),_c('div',{staticClass:"details"},[_c('h1',[_vm._v("Design")]),_c('p',[_vm._v(_vm._s(_vm.specialScenes.theme_data.name))])]),_c('div',{staticClass:"details"},[_c('h1',[_vm._v("Budget")]),_c('p',{class:_vm.requiredDomain == false ? 'blur' : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem(_vm.specialScenes.budget))+" ")])]),_c('div',{staticClass:"details"},[_c('h1',[_vm._v("Designed By")]),_c('p',[_vm._v(_vm._s(_vm.specialScenes.scene_detail.designer_name))])]),_c('router-link',{attrs:{"to":{
                    name: 'SceneDetailsPage',
                    params: { id: _vm.specialScenes.scene_detail.id },
                }}},[_c('Button',{attrs:{"name":"Explore the Design","primary":""}})],1)],1)]):_vm._e(),_c('div',{staticClass:"look-more-design"},[_vm._m(1),_c('p',[_vm._v("We know you love home decor designs. So here you go.")]),(Object.keys(_vm.inspirationalIdeas).length > 0)?_c('div',{staticClass:"design-list"},[_c('router-link',{attrs:{"to":{
                    name: 'RoomDesignIdeasPage',
                    params: { id: _vm.inspirationalIdeas.spaces[0].id },
                }}},[_c('Cards',{staticClass:"card",attrs:{"cardname":_vm.inspirationalIdeas.spaces[0].room,"img":_vm.inspirationalIdeas.spaces[0].image.p_image}})],1),_c('router-link',{attrs:{"to":{
                    name: 'RoomDesignIdeasPage',
                    params: { id: _vm.inspirationalIdeas.spaces[1].id },
                }}},[_c('Cards',{staticClass:"card",attrs:{"cardname":_vm.inspirationalIdeas.spaces[1].room,"img":_vm.inspirationalIdeas.spaces[1].image.p_image}})],1),_c('router-link',{attrs:{"to":{
                    name: 'RoomDesignIdeasPage',
                    params: { id: _vm.inspirationalIdeas.spaces[2].id },
                }}},[_c('Cards',{staticClass:"card",attrs:{"cardname":_vm.inspirationalIdeas.spaces[2].room,"img":_vm.inspirationalIdeas.spaces[2].image.p_image}})],1)],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading"},[_c('h1',[_vm._v("Customise Till Your Heart Sings")]),_c('p',[_vm._v(" No, really we mean it. There is no such thing as too many choices and we want you explore, have fun and be picky. So go ahead and create your perfect space. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h1',[_vm._v("Get Design Inspired")]),_c('a',{staticClass:"view-all",attrs:{"href":"/designideas"}},[_vm._v("View All")])])}]

export { render, staticRenderFns }